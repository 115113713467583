<template>
    <v-container fluid>
        <template>
            <v-row>
                <v-col cols="6" class="pb-1 pt-0">
                    <v-breadcrumbs :items="itemsBreadcrumbs" class="pl-0 pt-0 pb-1">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item
                            :to="item.href"
                            :disabled="item.disabled"
                            >
                            {{ item.text.toUpperCase() }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                </v-col>
                <v-col cols="6" class="pb-1 pt-0 h-100">
                    <office-login></office-login>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="10" class="pt-1">
                    <span class="headline font-weight-medium">Detalle de compra</span>
                </v-col>
                <v-col cols="2" class="pt-1">
                    <v-btn dark 
                        color="success" 
                        width="140" 
                        class="float-right"
                        @click="handleDownloadReport">
                        Descargar XLS
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="1" class="pt-0 pb-0">
                    <span>Fecha</span>
                </v-col>
                <v-col cols="8" class="pt-0 pb-0">
                    <span class="label-purchase">{{ purchase.register_date | moment("DD/MM/YYYY") }}</span>
                </v-col>
                <v-col cols="1" class="pt-0 pb-0">
                    <span class="label-discount">Descuento</span>
                </v-col>
                <v-col cols="2" class="pt-0 pb-0 column-amount">
                    <span class="label-purchase label-discount">{{ purchase.discount | currency('S/ ') }}</span>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="1" class="pt-0 pb-0">
                    <span>Proveedor</span>
                </v-col>
                <v-col cols="8" class="pt-0 pb-0">
                    <span class="label-purchase">{{ purchase.supplier.name }}</span>
                </v-col>
                <v-col cols="1" class="pt-0 pb-0">
                    <span>Subtotal</span>
                </v-col>
                <v-col cols="2" class="pt-0 pb-0 column-amount">
                    <span class="label-purchase">{{ purchase.subtotal | currency('S/ ') }}</span>
                </v-col>
            </v-row>            
            <v-row>
                <v-col cols="1" class="pt-0 pb-0">
                    <span>Almacén</span>
                </v-col>
                <v-col cols="8" class="pt-0 pb-0">
                    <span class="label-purchase">
                        {{ purchase.storehouse.name }}
                    </span>                    
                </v-col>
                <v-col cols="1" class="pt-0 pb-0">
                    <span>Total</span>
                </v-col>
                <v-col cols="2" class="pt-0 pb-0 column-amount">
                    <span class="label-purchase">{{ purchase.total | currency('S/ ') }}</span>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="1" class="pt-0 pb-0">
                    <span>{{ purchase.type_document | capitalize }}</span>
                </v-col>
                <v-col cols="8" class="pt-0 pb-0">
                    <span class="label-purchase">
                        {{ purchase.serie }}-{{ purchase.correlative }}
                    </span>
                </v-col>
                <v-col cols="1" class="pt-0 pb-0">
                    <span>IGV</span>
                </v-col>
                <v-col cols="2" class="pt-0 pb-0 column-amount">
                    <span class="label-purchase">{{ purchase.igv | currency('S/ ') }}</span>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" class="pt-2">
                    <v-card>
                        <v-data-table
                            :headers="headers"
                            :items="purchase.detail"
                            :items-per-page="10"
                            class="elevation-1 w-table"
                            height="57vh"
                            fixed-header
                        >   
                            <template v-slot:item.due_date="{item}">
                                <span v-if="item.due_date">
                                    {{ item.due_date | moment("DD/MM/YYYY") }}
                                </span>
                            </template>                

                            <template v-slot:item.quantity="{item}">
                                <span>{{ item.quantity }}</span>
                            </template>

                            <template v-slot:item.price="{item}">
                                <span>{{ item.price | currency('S/') }}</span>
                            </template>

                            <template v-slot:item.discount="{item}">
                                <span>{{ item.discount | currency('S/') }}</span>
                            </template>

                            <template v-slot:item.subtotal="{item}">
                                <span>{{ item.subtotal - item.discount | currency('S/') }}</span>
                            </template>

                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>
        </template>

        <imeis-dialog ref="imeisDialog"></imeis-dialog>
    </v-container>
</template>

<script>
import {mapState, mapActions} from 'vuex';
import router from '@/router/index';
import ImeisDialog from '../../../components/storehouses/ImeisDialog';
import OfficeLogin from '../../../components/auth/OfficeLogin';

export default {
    name: 'DetailPurchase',
    components: {
        ImeisDialog,
        OfficeLogin
    },
    data () {
        return {
            itemsBreadcrumbs: [
                {
                    text: 'Reportes',
                    disabled: true,
                    href: '',
                },
                {
                    text: 'Compras',
                    disabled: false,
                    href: '/app/reportes/reporte-compras',
                }
            ],
            headers: [
                { text: 'CATEGORIA', value: 'category_name', sortable: false },
                { text: 'MARCA', value: 'brand_name', sortable: false },                
                { text: 'NOMBRE', value: 'product_name', sortable: false },
                { text: 'F. VENC', value: 'due_date', sortable: false },            
                { text: 'CANTIDAD', value: 'quantity', sortable: false, align: 'end' },
                { text: 'PRECIO UND.', value: 'price', sortable: false, align: 'end' },
                { text: 'DESCUENTO', value: 'discount', sortable: false, align: 'end' },
                { text: 'TOTAL', value: 'subtotal', sortable: false, align: 'end' }
            ],
        }
    },
    computed: {
        ...mapState('report-purchases', [
            'purchase',
            'detail',
            'file_purchases_detail'
        ])
    },
    methods: {
        ...mapActions('report-purchases', [
            'getPurchasesDetail',
            'purchaseDetailExcel'
        ]),
        handleViewImeis (detail) {
            this.$refs.imeisDialog.show(detail.imei);
        },
        async handleDownloadReport () {
            await this.purchaseDetailExcel(this.purchase);
            if (this.file_purchases_detail) {
                let url = process.env.VUE_APP_API_URL_BASE + '/' + this.file_purchases_detail;
                window.open(url);
            }
        }
    }
}
</script>

<style scoped>
.column-amount{
    text-align: right;
}

.label-purchase{
    font-weight: 600;
}

.label-discount{
    color: #F44336;
}
</style>